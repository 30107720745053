import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "./style/main.scss";

const app = createRoot(document.getElementById("root"));
app.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
