import { BrowserRouter as Router } from "react-router-dom";
import { Path }  from "./routes";

export default function App() {
  return (
   
      <Router>
        <Path/>
      </Router>
  );
}
